import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Outlet } from "react-router-dom";
import axios from "axios";
import Config from '../config/config.json';
import { Link } from 'react-scroll';

function Header() {
    const [headerData, setHeaderData] = useState(null);
    const jobRef = useRef(null);
    const [menuListActive, setMenuListActive] = useState(false);
    const getHeaderData = async () => {
        const apiSiteURL = window.location.hostname === 'engineering.nbcunicareers.com' ? process.env.REACT_APP_API_SITE_URL_PROD : process.env.REACT_APP_API_SITE_URL_DEV;
        try {
            const res = await axios.get(
                apiSiteURL + Config.headerFooter.api
            );
            if (typeof res.data == 'object') {
                setHeaderData(res.data);
            } else {
                setHeaderData(null);
            }
            return res;
        } catch (err) {
            return console.error("Fetching data failed", err);
        }
    };

    useEffect(() => {
        getHeaderData();
    }, []);

    const onScroll = () => {
        const scrollPosition = window.scrollY;
        if (window.innerWidth < 768) {
            if ((scrollPosition > 0) && (scrollPosition < 400)) {
                jobRef.current.style.display = `none`;
            }
            else if (scrollPosition < 10) {
                jobRef.current.style.display = `flex`;
            }
        }
    }

    useLayoutEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [])

    const showMobMenu = () => {
        setMenuListActive(true);
        document.body.classList.add("scroll-hide");
    }

    const closeMobMenu = () => {
        setMenuListActive(false);
        document.body.classList.remove("scroll-hide");
    }

    return (
        <>
            {
                headerData ? (
                    <header id="header" className="header" role="navigation" aria-label="main navigation">
                        <div className="header-navbar">
                            <a href="/" title={headerData.header.siteName} rel="noreferrer" className="navbar-logo">
                                {
                                    headerData.header.headerLogo === 'null' ? (
                                        <img src="../../assets/images/nbcu-careers-logo.svg" alt="NBCUnicareers" className="d-inline-block align-top" />
                                    ) : (
                                        <img src={headerData.header.headerLogo} alt="NBCUnicareers" className="d-inline-block align-top" />
                                    )
                                }
                            </a>
                            {/* hamburger icon */}
                            <div className="mob-hamburger-menu" onClick={showMobMenu}>
                                <span className="hamburger-icon" ></span>
                            </div>
                            <div className={`mob-menu-close ${menuListActive ? "show" : ""}`} onClick={closeMobMenu}>
                                <span className="menu-close-icon" ></span>
                            </div>
                            {/* desktop header menu links */}
                            <div className={`menu-nav-links ${menuListActive ? "active" : ""}`}>
                                <ul className="menu-link-list">
                                    {
                                        headerData.header.menu && headerData.header.menu.map((data, i) =>
                                            <li key={i}>
                                                <Link activeClass="active" to={data.link} onClick={closeMobMenu} smooth={false}>{data.title}</Link>
                                            </li>
                                        )
                                    }
                                </ul>
                                {/* find a job link for mobile */}
                                <div className="mob-find-job-link">
                                    <a target="_blank" rel="noreferrer" href={headerData.header.mainMenu.url} title={headerData.header.mainMenu.url}>
                                        <span>{headerData.header.mainMenu.title}</span>
                                    </a>
                                </div>
                            </div>
                            <div className="nav-find-job" ref={jobRef}>
                                <a target="_blank" rel="noreferrer" href={headerData.header.mainMenu.url} title={headerData.header.mainMenu.url} className="find-job-link cust-btn">
                                    <span>{headerData.header.mainMenu.title}</span>
                                    <span><img src="../../assets/images/right-arrow-icon.svg" className="right-arrow" alt="Arrow" /></span>
                                </a>
                            </div>
                        </div>
                    </header>
                ) : (
                    null
                )
            }

            <Outlet />
        </>
    );
}

export default Header;