import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import Config from '../config/config.json';
import parse from 'html-react-parser';
// import ReactGA from "react-ga4";
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { Helmet } from 'react-helmet-async';

function OurVisionComponent() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [ourVisionData, setOurVisionData] = useState(null);
  //creating the ref
  const ourVisionDiv = useRef();

  const getOurVisionData = async () => {
    const apiSiteURL = window.location.hostname === 'engineering.nbcunicareers.com' ? process.env.REACT_APP_API_SITE_URL_PROD : process.env.REACT_APP_API_SITE_URL_DEV;
    try {
      const res = await axios.get(
        apiSiteURL + Config.leadership.api
      );
      if (typeof res.data == 'object') {
        setOurVisionData(res.data);
      } else {
        setOurVisionData(null);
      }
      return res;
    } catch (err) {
      return console.error("Fetching data failed", err);
    }
  };

  const onScroll = () => {
    const scrollPosition = window.scrollY;
    // const test = ourVisionDiv.current.offsetTop;
    if (scrollPosition > 700) {
      ourVisionDiv.current.style.opacity = `0`;
    }
    if (scrollPosition > 800) {
      ourVisionDiv.current.style.opacity = `0.2`;
    }
    if (scrollPosition > 900) {
      ourVisionDiv.current.style.opacity = `0.7`;
    }
    if (scrollPosition > 1000) {
      ourVisionDiv.current.style.opacity = `1`;
    }
  }

  useEffect(() => {
    Modal.setAppElement('body');
    AOS.init();
    AOS.refresh();
    getOurVisionData();

    // onscroll function called
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    // clean up code
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
    // Send a custom event
    window.gtag('event', 'click', {
      event_category: "Leadership_Category",
      event_action: "Leadership_Modal_Action",
      event_label: "Leadership_Label",
    });
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const onSuccess = () => {
    console.log("player success");
  };

  const onFailure = () => {
    console.log("player error");
  };

  return (
    <>
      {/* our vision wrapper */}
      {
        ourVisionData ? (
          <div className="our-vision-wrapper" ref={ourVisionDiv} id="section-1">
            {/* video section */}
            <div className="our-vision-video">
              <video aria-label="Leadership Video" className="vid" playsInline autoPlay muted loop>
                <source src={ourVisionData.videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <Helmet>
                <script type="application/ld+json">
                  {`
                                
                                
                    "@context": "https://schema.org/",
                    "@type": "VideoObject",
                    "url": ${ourVisionData.videoUrl},
                    "name": ${ourVisionData.bannerTitle},
                    "description": ${ourVisionData.bannerDesc}

                  }))
                  )}
                                    `}
                </script>
              </Helmet>
            </div>
            <div className="over-vision-description">
              <div>
                <h2 data-aos="fade-up"
                  data-aos-duration="1000" data-aos-offset="100" className="heading">{ourVisionData.bannerTitle}</h2>
                {
                  ourVisionData.bannerDesc ? (
                    <div data-aos="fade-up" data-aos-duration="1000" data-aos-offset="-100" className="description">{parse(ourVisionData.bannerDesc)}</div>
                  ) : null
                }

              </div>
            </div>
            <div className="vision-play-btn">
              <button type="button" className="button-play" onClick={openModal} data-aos="fade-in" data-aos-duration="500">
                <img src="../../assets/images/icon-play.png" className="icon-play" alt="Play Icon" />
              </button>
            </div>
            <div className="bg-dotted"></div>
          </div>
        ) : (
          null
        )
      }

      {/* video modal */}
      <Modal
        closeTimeoutMS={200}
        isOpen={modalIsOpen}
        contentLabel="Vision Modal"
      >
        {/* video section */}
        <button type="button" className="close-modal" onClick={closeModal}>
          <img src="../../assets/images/left-arrow.svg" alt="Close" className="back-arrow" />
          <span>Back</span>
        </button>
        {
          ourVisionData ? (
            <div className="vision-modal-wrapper">
              <div className="our-vision-video our-vision-modal">
                <div className="iframe-video">
                  <ReactPlayerLoader attrs={{ className: 'bright-video-wrap' }}
                    accountId={ourVisionData?.brightCoveAccountId}
                    videoId={ourVisionData?.brightCoveVideoId}
                    playerId="hP6kEvEiF"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    options={{
                      autoplay: true,
                      muted: false,
                      playsinline: true,
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            null
          )
        }
      </Modal>
    </>
  )
}

export default OurVisionComponent