import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import './styles/main.css';
import { osName } from 'react-device-detect';
//import ReactGA from "react-ga4";

// TRACKING_ID
//const TRACKING_ID = "G-40R225XRK2";
//ReactGA.initialize(TRACKING_ID);
//ReactGA.send("pageview");

if (osName === "Windows" || osName === "Android" || osName === "iOS") {
  document.body.classList.add("job-title-main");
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
