import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import Config from '../config/config.json';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";

function JobCategoriesComponent() {
    const [jobCategoriesData, setJobCategoriesData] = useState(null);
    const refToUse = useRef(null);

    const getJobCategoriesData = async () => {
        const apiSiteURL = window.location.hostname === 'engineering.nbcunicareers.com' ? process.env.REACT_APP_API_SITE_URL_PROD : process.env.REACT_APP_API_SITE_URL_DEV;
        try {
            const res = await axios.get(
                apiSiteURL + Config.jobCatergories.api
            );
            if (typeof res.data == 'object') {
                setJobCategoriesData(res.data);
            } else {
                setJobCategoriesData(null);
            }
            return res;
        } catch (err) {
            return console.error("Fetching data failed", err);
        }
    };

    useEffect(() => {
        getJobCategoriesData();
        AOS.init();
        AOS.refresh();
        Modal.setAppElement('body');
    }, []);


    const hideHeaderComponent = () => {
        document.body.classList.add("category-details");
    }

    return (
        <>
            {/* job category listing */}
            <div className="job-categories-container" ref={refToUse} id="section-2">
                <div className="job-categories-wrapper" data-aos="fade-in"
                    data-aos-duration="1000" data-aos-offset="100">
                    <h2 className="heading">Engineering Opportunities</h2>
                    <div className="engg-opptn-content">
                        Enterprise Engineering is part of Operations and Technology,
                        a global organization providing engineering & technology, operations, and facilities management
                        services to NBCUniversal's businesses and offices in the Tri-State area, London, Los Angeles
                        and Miami and is led by Ian Trombley, President Operations & Technology.
                    </div>
                    <div className="job-categories-container">
                        {
                            jobCategoriesData && jobCategoriesData.map((data, i) =>
                                <Link className="job-tiles" key={i} to={`/${data.path}`} onClick={hideHeaderComponent}>
                                    <div className="content-box">
                                        <h3 className="category-name">{parse(data.bannerTitle)}</h3>
                                    </div>
                                    <div className="learn-more-box">
                                        <span className="learn-text">Learn More</span>
                                        <img src="../../assets/images/more-right-icon.svg" className="learn-more-icon" alt="icon" />
                                    </div>
                                </Link>
                            )
                        }



                    </div>
                </div>
            </div>
        </>
    )
}

export default JobCategoriesComponent;