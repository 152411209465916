import React from 'react';
import Header from './Header';
import Footer from './Footer';

function Layout() {
    return (
        <>
            <Header />
            <Footer />
        </>
    )
}

export default Layout;