
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout";
import Home from "../pages/home";
import JobCategoryDepartment from "../pages/JobCategoryDepartment";
import PageNotFound from "../pages/PageNotFound";

function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/:path" element={<JobCategoryDepartment />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>

  );
}

export default Routing;