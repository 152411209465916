import React, { useState, useEffect, useRef, useCallback } from 'react';
import Slider from "react-slick";
import Modal from 'react-modal';
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import Config from '../config/config.json';
import parse from 'html-react-parser';
// import ReactGA from "react-ga4";
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { Helmet } from 'react-helmet-async';

function HeroBannerComponent() {
    const [showDesktopElement, setShowDesktopElement] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [heroSliderData, setHeroSliderData] = useState(null);
    const [modalData, setModalData] = useState(null);
    let [activeSlide, setActiveSlide] = useState(0);
    let [activeSlide2, setActiveSlide2] = useState(0);

    const ourRef = useRef(null);
    const contentRef = useRef(null);
    const bottomContentRef = useRef(null);
    const sliderRef = useRef(null);
    const arrowRef1 = useRef(null);
    const arrowRef2 = useRef(null);
    const bgDotted = useRef(null);
    const bgDottedSlider1 = useRef(null);
    const bgDottedSlider2 = useRef(null);
    const videoSliderRef = useRef([]);
    const sliderTitle = useRef([]);
    const sliderSubTitle = useRef([]);
    const sliderImgLeft = useRef([]);
    const sliderImgRight = useRef([]);
    const playBtn = useRef([]);
    const videoBgRef = useRef([]);
    const sliderCustDots = useRef([]);
    const desktopSlider = useRef(null);

    const getHeroSliderData = async () => {
        const apiSiteURL = window.location.hostname === 'engineering.nbcunicareers.com' ? process.env.REACT_APP_API_SITE_URL_PROD : process.env.REACT_APP_API_SITE_URL_DEV;
        try {
            const res = await axios.get(
                apiSiteURL + Config.heroSlider.api
            );
            if (typeof res.data == 'object') {
                setHeroSliderData(res.data);
            } else {
                setHeroSliderData(null);
            }
            return res;
        } catch (err) {
            return console.error("Fetching data failed", err);
        }
    };

    const onScroll = useCallback(() => {
        const scrollPosition = window.scrollY;
        bottomContentRef.current.style.display = `none`;
        contentRef.current.style.opacity = `0`;
        contentRef.current.style.visibility = `hidden`;
        if ((scrollPosition > 0) && (scrollPosition < 600)) {
            for (let i = 0; i < heroSliderData?.length; i++) {
                videoSliderRef.current[i].style.transform = `translate3d(0px, 0px, 0px) scale(${(100 - scrollPosition / 15) / 100})`;
                videoSliderRef.current[i].style.zIndex = `auto`;
                videoSliderRef.current[i].style.opacity = `0.5`;
                sliderImgLeft.current[i].style.display = `none`;
                sliderImgRight.current[i].style.display = `none`;
            }
            desktopSlider.current.style.position = `fixed`;
            desktopSlider.current.style.transform = `translate(0px, 0px)`;
            if (scrollPosition > 50) {
                contentRef.current.style.opacity = `0`;
                bottomContentRef.current.style.display = `none`;
                bgDotted.current.style.display = `none`;
            }
            if (scrollPosition > 100) {
                contentRef.current.style.opacity = `0`;
                contentRef.current.style.visibility = `hidden`;
            }
            if (scrollPosition > 200) {
                arrowRef1.current.style.display = `none`;
                arrowRef2.current.style.display = `none`;
                bgDottedSlider1.current.style.display = `none`;
                bgDottedSlider2.current.style.display = `none`;
                for (let i = 0; i < heroSliderData?.length; i++) {
                    sliderTitle.current[i].style.display = `none`;
                    sliderSubTitle.current[i].style.opacity = `0`;
                    playBtn.current[i].style.display = `none`;
                    sliderCustDots.current[i].style.display = `none`;
                }
            }

            if (scrollPosition > 300) {
                arrowRef1.current.style.display = `flex`;
                arrowRef1.current.style.opacity = `0.3`;
                arrowRef2.current.style.display = `flex`;
                arrowRef2.current.style.opacity = `0.3`;
                bgDottedSlider1.current.style.display = `none`;
                bgDottedSlider2.current.style.display = `none`;
                for (let i = 0; i < heroSliderData?.length; i++) {
                    sliderTitle.current[i].style.display = `none`;
                    sliderSubTitle.current[i].style.opacity = `0`;
                    sliderImgLeft.current[i].style.display = `block`;
                    sliderImgRight.current[i].style.display = `block`;
                    sliderImgLeft.current[i].style.opacity = `0.5`;
                    sliderImgRight.current[i].style.opacity = `0.5`;
                    videoSliderRef.current[i].style.zIndex = `10`;
                    videoSliderRef.current[i].style.opacity = `1`;
                    playBtn.current[i].style.display = `none`;
                    sliderCustDots.current[i].style.display = `none`;
                }
            }

            if (scrollPosition > 400) {
                arrowRef1.current.style.opacity = `1`;
                arrowRef2.current.style.opacity = `1`;
                bgDottedSlider1.current.style.display = `block`;
                bgDottedSlider2.current.style.display = `block`;
                for (let i = 0; i < heroSliderData?.length; i++) {
                    sliderImgLeft.current[i].style.display = `block`;
                    sliderImgRight.current[i].style.display = `block`;
                    sliderImgLeft.current[i].style.opacity = `0.75`;
                    sliderImgRight.current[i].style.opacity = `0.75`;
                    sliderTitle.current[i].style.display = `block`;
                    sliderSubTitle.current[i].style.opacity = `1`;
                    playBtn.current[i].style.display = `block`;
                    sliderCustDots.current[i].style.display = `inline-block`;
                }
            }
        } else if (scrollPosition < 10) {
            for (let i = 0; i < heroSliderData?.length; i++) {
                videoSliderRef.current[i].style.transform = `scale(${(100 + scrollPosition / 3) / 100})`;
                videoSliderRef.current[i].style.zIndex = `auto`;
                videoSliderRef.current[i].style.opacity = `0.5`;
                sliderTitle.current[i].style.display = `none`;
                sliderSubTitle.current[i].style.opacity = `0`;
                sliderImgLeft.current[i].style.display = `none`;
                sliderImgRight.current[i].style.display = `none`;
                playBtn.current[i].style.display = `none`;
                sliderCustDots.current[i].style.display = `none`;
            }
            ourRef.current.style.marginTop = `0`;
            ourRef.current.style.marginBottom = `0`;
            contentRef.current.style.opacity = `1`;
            contentRef.current.style.visibility = `visible`;
            bottomContentRef.current.style.display = `block`;
            arrowRef1.current.style.display = `none`;
            arrowRef2.current.style.display = `none`;
            bgDotted.current.style.display = `block`;
            bgDotted.current.style.animation = `none`;
            bgDottedSlider1.current.style.display = `none`;
            bgDottedSlider2.current.style.display = `none`;

            desktopSlider.current.style.position = `relative`;

            // ourRef.current.style.paddinBottom = `0`;
        } else {
            desktopSlider.current.style.position = `relative`;
            desktopSlider.current.style.transform = `translate(0px, 600px)`;
            ourRef.current.style.paddinBottom = `0`;
        }

        // for mobile
        if (window.innerWidth < 768) {
            if ((scrollPosition > 0) && (scrollPosition < 600)) {
                for (let i = 0; i < heroSliderData?.length; i++) {
                    videoSliderRef.current[i].style.transform = `translate3d(0px, 0px, 0px) scale(${(100 - scrollPosition / 20) / 100})`;
                    videoSliderRef.current[i].style.height = `${(100 - scrollPosition / 25)}%`;
                    // videoBgRef.current[i].style.objectFit = `contain`;
                }
            } else if (scrollPosition < 10) {
                for (let i = 0; i < heroSliderData?.length; i++) {
                    videoSliderRef.current[i].style.transform = `scale(1)`;
                    // videoBgRef.current[i].style.objectFit = `cover`;
                }
            } else {
                desktopSlider.current.style.transform = `translate(0px, 600px)`;
            }
        }
    }, [heroSliderData?.length]);

    useEffect(() => {
        getHeroSliderData();
        setTimeout(function () {
            setShowDesktopElement(true);
        }, 2000);

        Modal.setAppElement('body');
        AOS.init();
        AOS.refresh();

        // onscroll function called
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        // clean up code
        return () => window.removeEventListener('scroll', onScroll);
    }, [onScroll]);

    let totalSlides = heroSliderData?.length;
    const next = () => {
        // console.log(activeSlide);
        activeSlide = (activeSlide + 1) % totalSlides;
        sliderRef.current.slickGoTo(activeSlide);
    };
    const previous = () => {
        // console.log(activeSlide2);
        activeSlide = (activeSlide - 1 + totalSlides) % totalSlides;
        sliderRef.current.slickGoTo(activeSlide);
    };

    const openModal = (data) => {
        setModalData(data);
        setModalIsOpen(true);
        // Send a custom event
        window.gtag('event', 'click', {
            event_category: "Hero_Slider_Category",
            event_action: "Hero_Slider_Modal_Action",
            event_label: "Hero_Slider_Modal",
        });
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50
    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe) {
            console.log("isLeftSwipe true");
            if (activeSlide === (totalSlides - 1)) {
                sliderRef.current.slickGoTo(0);
            } else {
                sliderRef.current.slickNext();
            }
        } else if (isRightSwipe) {
            console.log("isRightSwipe true");
            if (activeSlide === 0) {
                sliderRef.current.slickGoTo(totalSlides - 1);
            } else {
                sliderRef.current.slickPrev();
            }
        }
    }

    const onSwipeSlide = (direction) => {
        if (sliderRef.current) {
            if (direction === "left") {
                console.log("left");
                if (activeSlide === (totalSlides - 1)) {
                    console.log("true")
                    sliderRef.current.slickGoTo(0);
                } else {
                    sliderRef.current.slickNext();
                }
            } else if (direction === "right") {
                console.log("right");
                if (activeSlide === 0) {
                    sliderRef.current.slickGoTo(totalSlides - 1);
                } else {
                    sliderRef.current.slickPrev();
                }
            }
        }

    }

    var settings = {
        dots: true,
        arrow: false,
        infinite: false,
        swipe: false,
        swipeToSlide: false,
        speed: 0,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setActiveSlide(next),
        afterChange: current => setActiveSlide2(current),
        // onSwipe: onSwipeSlide.bind(this),
        accessibility: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    speed: 500,
                }
            }
        ],
        appendDots: dots => <ul>{dots}</ul>,
        customPaging: i => (
            <div className="custom-dots" ref={(customDots) => {
                sliderCustDots.current[i] = customDots;
            }}>
            </div>
        )
    };

    const onSuccess = () => {
        console.log("player success");
    };

    const onFailure = () => {
        console.log("player error");
    };

    return (
        <>
            <div className='position-relative'>
                <div className="hero-slider-main-wrapper" ref={ourRef} id="section-0">
                    {/* hero banner static title start */}
                    <div className="hero-midddle-content" ref={contentRef}>
                        <h1 className="h1 main-heading text-uppercase">NBCUniversal</h1>
                        <div className="stroke-text"><svg width="560" height="69" viewBox="0 0 560 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 67.6H39.97V53.02H16.66V41.86H39.97V27.28H16.66V16.48H39.97V1.9H1V67.6Z" stroke="white" />
                            <path d="M46.0664 67.6H61.7264V28.27L86.5664 67.6H102.136V1.9H86.5664V39.34L62.8064 1.9H46.0664V67.6Z" stroke="white" />
                            <path d="M142.103 68.5C161.093 68.5 173.963 53.56 173.963 36.19V28.72H138.683V43.3H156.953C154.703 49.78 149.393 53.56 141.833 53.56C130.943 53.56 123.293 45.91 123.293 34.75C123.293 23.95 131.393 15.85 141.563 15.85C148.853 15.85 154.433 19.18 157.493 25.03L171.353 17.38C165.683 7.84 155.243 1 141.473 1C121.673 1 107.633 15.67 107.633 34.75C107.633 52.93 120.683 68.5 142.103 68.5Z" stroke="white" />
                            <path d="M179.887 67.6H195.727V1.9H179.887V67.6Z" stroke="white" />
                            <path d="M202.447 67.6H218.107V28.27L242.947 67.6H258.517V1.9H242.947V39.34L219.187 1.9H202.447V67.6Z" stroke="white" />
                            <path d="M265.004 67.6H303.974V53.02H280.664V41.86H303.974V27.28H280.664V16.48H303.974V1.9H265.004V67.6Z" stroke="white" />
                            <path d="M310.07 67.6H349.04V53.02H325.73V41.86H349.04V27.28H325.73V16.48H349.04V1.9H310.07V67.6Z" stroke="white" />
                            <path d="M355.137 67.6H370.797V39.34L386.277 67.6H404.007L390.327 44.47C398.337 40.78 402.117 32.95 402.117 24.49C402.117 12.07 393.207 1.9 377.367 1.9H355.137V67.6ZM376.197 16.3C382.767 16.3 386.187 19.27 386.187 24.67C386.187 29.26 382.857 33.58 376.197 33.58H370.797V16.3H376.197Z" stroke="white" />
                            <path d="M408.647 67.6H424.487V1.9H408.647V67.6Z" stroke="white" />
                            <path d="M431.207 67.6H446.867V28.27L471.707 67.6H487.277V1.9H471.707V39.34L447.947 1.9H431.207V67.6Z" stroke="white" />
                            <path d="M527.244 68.5C546.234 68.5 559.104 53.56 559.104 36.19V28.72H523.824V43.3H542.094C539.844 49.78 534.534 53.56 526.974 53.56C516.084 53.56 508.434 45.91 508.434 34.75C508.434 23.95 516.534 15.85 526.704 15.85C533.994 15.85 539.574 19.18 542.634 25.03L556.494 17.38C550.824 7.84 540.384 1 526.614 1C506.814 1 492.774 15.67 492.774 34.75C492.774 52.93 505.824 68.5 527.244 68.5Z" stroke="white" />
                        </svg>
                        </div>
                    </div>
                    {/* hero slider start */}
                    {showDesktopElement ? <div className="desktop-hero-slider" ref={desktopSlider}>
                        <button type="button" className="cust-arrows arrow-prev" onClick={previous} ref={arrowRef1}>
                            <span>Previous</span>
                        </button>
                        <div className="bg-left" ref={bgDottedSlider1}>
                            <img src="../../assets/images/dots-lower.svg" alt="" />
                        </div>
                        <Slider {...settings} ref={sliderRef}>
                            {
                                heroSliderData && heroSliderData.map((data, i) =>
                                    <div className="hero-slider" key={i} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                                        <div className="hero-slider-items" ref={(element) => {
                                            videoSliderRef.current[i] = element;
                                        }}>
                                            <video aria-label="Slider Video" playsInline autoPlay muted loop ref={(videoBg) => {
                                                videoBgRef.current[i] = videoBg;
                                            }}>
                                                <source src={data.heroBannerVideo} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <div className="desk-side-images left-side" ref={(imageLeft) => {
                                            sliderImgLeft.current[i] = imageLeft;
                                        }}>
                                            <img src={data.leftThumbnail} alt="" />
                                        </div>
                                        <div className="desk-side-images right-side" ref={(imageRight) => {
                                            sliderImgRight.current[i] = imageRight;
                                        }}>
                                            <img src={data.rightThumbnail} alt="" />
                                        </div>
                                        <div className="slider-content">
                                            <h2 ref={(title) => {
                                                sliderTitle.current[i] = title;
                                            }}>{data.bannerTitle}</h2>
                                            {
                                                data.bannerDesc ? (
                                                    <h4 ref={(subTitle) => {
                                                        sliderSubTitle.current[i] = subTitle;
                                                    }}>{parse(data.bannerDesc)}</h4>
                                                ) : null
                                            }

                                        </div>
                                        <div className="vision-play-btn" ref={(playButton) => {
                                            playBtn.current[i] = playButton;
                                        }}>
                                            <div className="button-play" onClick={() => openModal(data)}>
                                                <img src="../../assets/images/icon-play.png" className="icon-play" alt="Play Icon" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </Slider>
                        <Helmet>


                            <script type="application/ld+json">
                                {`
                                ${JSON.stringify(heroSliderData.map((video, index) => ({

                                    "@context": "https://schema.org/",
                                    "@type": "VideoObject",
                                    "url": video.heroBannerVideo,
                                    "name": video.bannerTitle,
                                    "description": video.bannerDesc

                                }))
                                )}
                               
                                    `}
                            </script>
                            <script type="application/ld+json">
                                {`
                                
                                ${JSON.stringify(heroSliderData.map((image, index) => ({

                                    "@context": "https://schema.org/",
                                    "@type": "ImageObject",
                                    "url": image.leftThumbnail,
                                    "name": "Thumbnail",
                                    "description": "Left Thumbnail"

                                }))
                                )}
                                    `}
                            </script>
                            <script type="application/ld+json">
                                {`
                                
                                ${JSON.stringify(heroSliderData.map((image, index) => ({

                                    "@context": "https://schema.org/",
                                    "@type": "ImageObject",
                                    "url": image.rightThumbnail,
                                    "name": "Thumbnail",
                                    "description": "Right Thumbnail"

                                }))
                                )}
                                    `}
                            </script>
                        </Helmet>
                        <div className="bg-right" ref={bgDottedSlider2}>
                            <img src="../../assets/images/dots-upper.svg" alt="" />
                        </div>
                        <button type="button" className="cust-arrows arrow-next" onClick={next} ref={arrowRef2}>
                            <span>Next</span>
                        </button>
                    </div> : <div className="bg-dark"></div>}
                    <div className="hero-slider-bottom" ref={bottomContentRef}>
                        <p>A media company fueled by people who love bleeding edge tech</p>
                    </div>
                    <div className="bg-dotted" ref={bgDotted}></div>
                </div>
            </div>

            {/* video modal */}
            <Modal
                closeTimeoutMS={200}
                isOpen={modalIsOpen}
                contentLabel="Vision Modal"
            >
                {/* video section */}
                <button type="button" className="close-modal" onClick={closeModal}>
                    <img src="../../assets/images/left-arrow.svg" alt="Close" className="back-arrow" />
                    <span>Back</span>
                </button>

                <div className="vision-modal-wrapper hero-modal-wrapper">
                    <div className="our-vision-video our-vision-modal">
                        <div className="iframe-video">
                            <ReactPlayerLoader attrs={{ className: 'bright-video-wrap' }}
                                accountId={modalData?.heroMainBrightCoveAccountId}
                                playlistVideoId={modalData?.heroMainBrightCoveVideoId}
                                playlistId="1752856156412255523"
                                playerId="hP6kEvEiF"
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                options={{
                                    autoplay: true,
                                    muted: false,
                                    playsinline: true,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    )
}

export default HeroBannerComponent;