import React, { useEffect } from 'react';

function PageNotFound() {
    useEffect(() => {
        document.title = "Page Not Found | Engineering";
        document.body.classList.add("not-found-error");
    }, []);
    return (
        <>
            <div className="page-not-found-wrap">
                <img src="../../assets/images/Icon-Error.png" alt="" className="mb-4" />
                <h2 className="mb-3">404 Error</h2>
                <h5>Sorry, this page cannot be found.</h5>
            </div>
        </>
    )
}

export default PageNotFound;