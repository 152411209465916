import React, { useState, useEffect } from "react";

function ScrollDownComponent(props) {
    const [showDownBtn, setShowDownBtn] = useState(true);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setShowDownBtn(false);
            } else {
                setShowDownBtn(true);
            }
        });
    }, []);

    return (
        <>
            {showDownBtn && (
                <div className="arrow" onClick={props.scrollDown}>
                    <p className="scroll-down-txt">Scroll Down</p>
                    <div className="arrow-icons">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>

            )}
        </>
    )
}

export default ScrollDownComponent