import React, { useRef, useState, useEffect } from 'react';
import HeroBannerComponent from '../components/HeroBannerComponent';
import OurVisionComponent from '../components/OurVisionComponent';
import JobCategoriesComponent from '../components/JobCategoriesComponent';
import ScrollDownComponent from '../components/ScrollDownComponent';
import { osName } from 'react-device-detect';
import WorkLocationsComponent from '../components/WorkLocationsComponent';

function Home() {
    const ourVision = useRef(null);
    const [showScrollDown, setShowScrollDown] = useState(false);

    useEffect(() => {
        if (osName === "Windows" || osName === "Android" || osName === "iOS") {
            document.body.classList.add("job-title-main");
        }

        setTimeout(function () {
            setShowScrollDown(true);
        }, 2000);
        // window.scrollTo(0, 0);
        document.documentElement.scrollTo(0, 0);
        document.title = "Home | Careers Engineering";
    }, [])

    const clickScroll = () => {
        window.scrollTo({
            top: ourVision.current.offsetTop,
            behavior: 'smooth',
        });
    };

    return (
        <div>

            <main id="site-content" className="main-content" role="main" aria-label="main content">
                <section>
                    {
                        showScrollDown ? <ScrollDownComponent scrollDown={() => clickScroll()} /> : null
                    }

                </section>
                <section>
                    <HeroBannerComponent />
                </section>
                <section ref={ourVision}>
                    <OurVisionComponent />
                </section>
                <section>
                    <JobCategoriesComponent />
                </section>
                <section>
                    <WorkLocationsComponent />
                </section>
            </main>

        </div>
    )
}

export default Home;