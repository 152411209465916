import React, { useState, useEffect, useRef } from 'react';
import "aos/dist/aos.css";
import axios from "axios";
import Config from '../config/config.json';
import parse from 'html-react-parser';
import { useParams } from "react-router-dom";
// import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function JobCategoryDetails(props) {
    const { path } = useParams();
    const [jobCategoryDetailsData, setJobCategoryDetailsData] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [jobCategVideoModalData, setJobCategVideoModalData] = useState(null);
    const [showJobDownBtn, setShowJobDownBtn] = useState(true);
    const jobCagtModal = useRef(null);

    // coverting path to page title
    const pageTitleStr = path;
    const pageTitleArr = pageTitleStr.split("-");
    for (var i = 0; i < pageTitleArr.length; i++) {
        pageTitleArr[i] = pageTitleArr[i].charAt(0).toUpperCase() + pageTitleArr[i].slice(1);
    }
    const mainPageTitle = pageTitleArr.join(" ") + " | Engineering";

    useEffect(() => {
        const getJobCategoryDetailsData = async () => {
            const apiSiteURL = window.location.hostname === 'engineering.nbcunicareers.com' ? process.env.REACT_APP_API_SITE_URL_PROD : process.env.REACT_APP_API_SITE_URL_DEV;
            try {
                const res = await axios.get(
                    apiSiteURL + Config.jobCategoryDetails.api + path
                );
                if (typeof res.data == 'object') {
                    setJobCategoryDetailsData(res.data);
                } else {
                    setJobCategoryDetailsData(null);
                }
                return res;
            } catch (err) {
                return console.error("Fetching data failed", err);
            }
        };
        Modal.setAppElement('body');
        document.documentElement.scrollTo(0, 0);
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setShowJobDownBtn(false);
            } else {
                setShowJobDownBtn(true);
            }
        });
        getJobCategoryDetailsData();

    }, [path]);

    const openModal = (jobCategoryDetailsData) => {
        setModalIsOpen(true);
        setJobCategVideoModalData(jobCategoryDetailsData);
        // Send a custom event
        window.gtag('event', 'click', {
            event_category: "Job_Category_Modal_Description",
            event_action: "Job_Category_Modal_Action",
            event_label: "Job_Category_Modal_Label",
        });
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }

    const showHeader = () => {
        document.body.classList.remove("category-details");
    }

    const clickDownScroll = () => {
        window.scrollTo({
            top: jobCagtModal.current.offsetTop,
            behavior: 'smooth',
        });
    };

    const onSuccess = () => {
        console.log("player success");
    };

    const onFailure = () => {
        console.log("player error");
    };

    if (jobCategoryDetailsData?.length === 0) {
        document.title = "Page Not Found | Engineering";
        document.body.classList.add("not-found-error");
        return (
            <div className="page-not-found-wrap">
                <img src="../../assets/images/Icon-Error.png" alt="" className="mb-4" />
                <h2 className="mb-3">404 Error</h2>
                <h5>Sorry, this page cannot be found.</h5>
            </div>
        )
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{mainPageTitle}</title>
                    <meta property="og:title" content={mainPageTitle} />
                </Helmet>
            </HelmetProvider>

            {showJobDownBtn && (
                <div className="arrow job-cagt-arrow" onClick={clickDownScroll}>
                    <p className="scroll-down-txt">Scroll Down</p>
                    <div className="arrow-icons">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            )}
            {
                jobCategoryDetailsData && jobCategoryDetailsData?.length !== 0 ? (
                    <div className="job-page-wrapper">
                        <div className="job-categ-description">
                            <Link to="/" className="back-wrap" onClick={showHeader}>
                                <img src="../../assets/images/left-arrow.svg" className="arrow-img" alt="Back Arrow" />
                                <span>Back</span>
                            </Link>
                            {/* video section */}
                            <div className="job-description-banner">
                                <img src={jobCategoryDetailsData?.bannerUrl} alt="Job Banner" className="" />
                            </div>
                            <div className="job-categ-content">
                                <div className="text-with-bg">
                                    <h2 className="text-uppercase job-categ-title">
                                        <span className="indent">
                                            {
                                                jobCategoryDetailsData?.jobCategoryDetailTitle === null ? (
                                                    <span className="cust-heading">{jobCategoryDetailsData?.bannerTitle}</span>
                                                ) : (
                                                    jobCategoryDetailsData?.jobCategoryDetailTitle?.split("\n").map(function (item, idx) {
                                                        return (
                                                            <span className="cust-heading" key={idx}>
                                                                {item}
                                                                <br />
                                                            </span>
                                                        )
                                                    })
                                                )
                                            }
                                        </span>

                                    </h2>
                                </div>
                                <div className="view-open-roles">
                                    <a href={jobCategoryDetailsData?.positionLink} className="role-link" title="View Open Roles" target="_blank" rel="noreferrer">
                                        <span>View Open Roles</span>
                                        <img src="../../assets/images/more-right-icon.svg" className="arrow-img" alt="icon" />
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div className="job-categ-middle-content" ref={jobCagtModal}>
                            <div className="job-content-row">
                                <div className="play-videos-wrapper">
                                    <div>
                                        {
                                            jobCategoryDetailsData?.bannerVideoThumb && jobCategoryDetailsData?.categoryVideoBrightCoveAccountId && jobCategoryDetailsData?.categoryVideoBrightCoveVideoId ? (
                                                <img className="job-content-video" src={jobCategoryDetailsData?.bannerVideoThumb} alt="Job Category" />
                                            ) : (
                                                <img src={jobCategoryDetailsData?.bannerVideoThumb} alt="Job Category" />
                                            )
                                        }

                                    </div>
                                    {
                                        jobCategoryDetailsData?.categoryVideoBrightCoveAccountId && jobCategoryDetailsData?.categoryVideoBrightCoveVideoId ? (
                                            <div className="vision-play-btn">
                                                <button type="button" className="button-play" onClick={() => openModal(jobCategoryDetailsData)}>
                                                    <img src="../../assets/images/icon-play.png" className="icon-play" alt="Play Icon" />
                                                </button>
                                            </div>
                                        ) : null
                                    }

                                </div>
                                <div className="full-description">
                                    <h3>{jobCategoryDetailsData?.bannerSubTitle}</h3>
                                    {
                                        jobCategoryDetailsData?.bannerDesc ? (
                                            <div>{parse(jobCategoryDetailsData?.bannerDesc)}</div>
                                        ) : null
                                    }

                                    {
                                        jobCategoryDetailsData?.positionLink ? (
                                            <div className="view-open-roles">
                                                <a href={jobCategoryDetailsData?.positionLink} className="role-link" target="_blank" title="View Open Roles" rel="noreferrer">
                                                    <span>View Open Roles</span>
                                                    <img src="../../assets/images/more-right-icon.svg" className="arrow-img" alt="icon" />
                                                </a>
                                            </div>
                                        ) : null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="empty-div"></div>
                )
            }

            {/* video modal */}
            <Modal
                closeTimeoutMS={200}
                isOpen={modalIsOpen}
                contentLabel="Vision Modal"
            >
                {/* video section */}
                <button type="button" className="close-modal" onClick={closeModal}>
                    <img src="../../assets/images/left-arrow.svg" alt="Close" className="back-arrow" />
                    <span>Back</span>
                </button>
                <div className="vision-modal-wrapper">
                    <div className="our-vision-video our-vision-modal">
                        <div className="iframe-video">
                            <ReactPlayerLoader attrs={{ className: 'bright-video-wrap' }}
                                accountId={jobCategVideoModalData?.categoryVideoBrightCoveAccountId}
                                playlistVideoId={jobCategVideoModalData?.categoryVideoBrightCoveVideoId}
                                playlistId="1752859071536262428"
                                playerId="hP6kEvEiF"
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                options={{
                                    autoplay: true,
                                    muted: false,
                                    playsinline: true,
                                }}
                            />

                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default JobCategoryDetails;