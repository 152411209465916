import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import axios from "axios";
import Config from '../config/config.json';
import parse from 'html-react-parser';

function WorkLocationsComponent() {
    const [workLocationData, setWorkLocationData] = useState(null);
    const sliderRef1 = useRef(null);
    const sliderRef2 = useRef(null);
    const [active, setActive] = useState(0);

    // fetch data
    const getWorkLocationData = async () => {
        const apiSiteURL = window.location.hostname === 'engineering.nbcunicareers.com' ? process.env.REACT_APP_API_SITE_URL_PROD : process.env.REACT_APP_API_SITE_URL_DEV;
        try {
            const res = await axios.get(
                apiSiteURL + Config.featuredLocations.api
            );
            if (typeof res.data[0].rows == 'object') {
                setWorkLocationData(res.data[0].rows);
            } else {
                setWorkLocationData(null);
            }
            return res;
        } catch (err) {
            return console.error("Fetching data failed", err);
        }
    };

    useEffect(() => {
        getWorkLocationData();
    }, []);

    // toggle event
    const toggleClass = (i) => {
        sliderRef2.current.slickGoTo(i);
        setActive(i);
    };

    // on swipe event
    const onSwipe = (direction) => {
        if (sliderRef1.current) {
            sliderRef1.current.innerSlider.clickable = true;
            if (direction === "left") {
                sliderRef2.current.slickNext();
            } else if (direction === "right") {
                sliderRef2.current.slickPrev();
            }
        }
    }

    // next function
    const next = () => {
        sliderRef1.current.slickNext();
    };

    // previous function
    const previous = () => {
        sliderRef1.current.slickPrev();
    };

    // slider 1 setting
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        focusOnSelect: false,
        responsive: [{
            breakpoint: 5000,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: 0,
            }
        }, {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                centerPadding: 0,
                centerMode: false,
            }
        }, {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: true,
                focusOnSelect: true,
                centerPadding: "10px",
                swipeToSlide: true,
                onSwipe: onSwipe.bind(this),
            }
        }, {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
                focusOnSelect: true,
                centerPadding: "10%",
                swipeToSlide: true,
                onSwipe: onSwipe.bind(this),
            }
        }, {
            breakpoint: 476,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                focusOnSelect: true,
                centerPadding: "20%",
                swipeToSlide: true,
                onSwipe: onSwipe.bind(this),
            }
        }]
    };

    // slider 2 setting
    const settings1 = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        accessibility: false,
        adaptiveHeight: true,
        draggable: false,
        swipe: false,
    }

    return (
        <>
            {
                workLocationData ? (
                    <div className="work-location-container" id="section-3">
                        <div className="work-location-wrapper">
                            <h2 className="heading">Engineering Locations</h2>
                            {/* location slider */}
                            <div className="location-slider">
                                <div>
                                    <button type="button" className="loc-cust-arrow loc-prev" onClick={previous}>
                                    </button>
                                    <Slider {...settings} ref={sliderRef1}>
                                        {
                                            workLocationData && workLocationData.map((data, i) =>
                                                <div key={i} className={`location-slide ${active === i && 'active'}`}
                                                    onClick={() => toggleClass(i)}>
                                                    <div className="location-slider-images">
                                                        <div className="location-default-image">
                                                            <img src={data.field_featured_image} alt="Location Default" className="img-location" />
                                                        </div>
                                                        <div className="location-hover-image">
                                                            <img src={data.field_featured_hover_image} alt="Location Active" className="img-location" />
                                                        </div>
                                                    </div>
                                                    <div className="location-border-default"></div>
                                                    <div className="location-border-active" style={{ background: `${data.field_featured_hover_color}` }}></div>
                                                    <div className="location-title">{data.name}</div>
                                                    <div className="location-title-border" style={{ background: `${data.field_featured_hover_color}` }}></div>
                                                </div>
                                            )
                                        }
                                    </Slider>
                                    <button type="button" className="loc-cust-arrow loc-next" onClick={next}>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            <Slider {...settings1} ref={sliderRef2}>
                                {
                                    workLocationData && workLocationData.map((contentData, i) =>
                                        <div key={i} className="location-bottom-section">
                                            <div className="location-column-flex">
                                                <div className="location-work-desc">
                                                    <div className="location-desc-inner">
                                                        <div className="location-desc-content">
                                                            <div className="content-left">
                                                                <div className="title">{contentData.name}</div>
                                                                <div className="view-open-roles">
                                                                    {
                                                                        contentData.field_engineering_open_jobs_link ? (
                                                                            <a href={contentData.field_engineering_open_jobs_link.replace(/&amp;/g, "&")} className="role-link" title="View Open Roles" target="_blank" rel="noreferrer">
                                                                                <span>View Open Roles</span>
                                                                                <img src="../../assets/images/more-right-icon.svg" className="arrow-img" alt="icon" />
                                                                            </a>
                                                                        ) : (
                                                                            null
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="content-right">{parse(contentData.description__value)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="location-work-banner">
                                                    {
                                                        contentData.field_featured_banner_image ? (
                                                            <img alt="Location Banner" src={contentData.field_featured_banner_image} className="banner-img" />
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </Slider>
                        </div>
                    </div>
                ) : (
                    null
                )
            }
        </>
    )
}

export default WorkLocationsComponent;