import React, { useState, useEffect } from "react";
import axios from "axios";
import Config from '../config/config.json';
import Modal from "react-modal";

function JoinTalentCommunity() {
    const [talentData, setTalentData] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [regionIframeData, setRegionIframeData] = useState(null);
    const [showRegionList, sethowRegionList] = useState(true);
    const [showRegionIframe, setShowRegionIframe] = useState(false);

    const getTalentData = async () => {
        const apiSiteURL = window.location.hostname === 'engineering.nbcunicareers.com' ? process.env.REACT_APP_API_SITE_URL_PROD : process.env.REACT_APP_API_SITE_URL_DEV;
        try {
            const res = await axios.get(
                apiSiteURL + Config.joinTalentCommunity.api
            );
            if (typeof res.data == 'object') {
                setTalentData(res.data);
            } else {
                setTalentData(null);
            }
            return res;
        } catch (err) {
            return console.error("Fetching data failed", err);
        }
    };

    useEffect(() => {
        getTalentData();
        Modal.setAppElement("body");
    }, []);

    const openModal = () => {
        document.body.classList.add("talent-commt-modal");
        setModalIsOpen(true);
        sethowRegionList(true);
    };

    const closeModal = () => {
        document.body.classList.remove("talent-commt-modal");
        setModalIsOpen(false);
        sethowRegionList(false);
        setShowRegionIframe(false);
    };

    const showIframeData = (innerData) => {
        setRegionIframeData(innerData);
        setShowRegionIframe(true);
        sethowRegionList(false);
    }
    return (
        <>
            <div className="talent-commt-wrapper">
                <h6 className="link-title" onClick={openModal}>Join our talent community</h6>
            </div>
            {/* Talent Community modal */}
            <Modal
                isOpen={modalIsOpen}
                contentLabel="Talent Community Modal"
                className="talent-modal"
            >
                {
                    talentData ? (
                        <div className="talent-modal-main">
                            <div className="talent-modal-header">
                                <h6>{talentData.title}</h6>
                                <button type="button" className="close-talent-modal" onClick={closeModal} aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {
                                showRegionList ?
                                    <div className="talent-modal-body">
                                        <h6>{talentData.subTitle}</h6>
                                        <ul className="region-list">
                                            {
                                                talentData?.regions && talentData?.regions?.map((innerData, i) =>
                                                    <li key={i} className="region-link-item" onClick={() => showIframeData(innerData)}>
                                                        <span className="title">{innerData.title}</span>
                                                        <span><img src="../../assets/images/more-right-icon.svg" className="region-more" alt="icon" /></span>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div> : null
                            }

                            {
                                showRegionIframe ?
                                    <div className="region-iframe-wrapper">
                                        <iframe className="iframe-wrapper" src={regionIframeData?.link} title="Region Iframe"></iframe>
                                    </div> : null
                            }


                        </div>
                    ) : (
                        null
                    )
                }

            </Modal>
        </>
    );
}

export default JoinTalentCommunity;
