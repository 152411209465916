import React, { useEffect, useState } from "react";
import axios from "axios";
import Config from '../config/config.json';
import ScrollTopComponent from "./ScrollTopComponent";
import JoinTalentCommunity from "./JoinTalentCommunity";

function Footer() {
    const [footerData, setFooterData] = useState(null);
    const [choiceIcon, setChoiceIcon] = useState(false);
    const [cookieButtonText, setCookieButtonText] = useState("Your Privacy Choices");

    const getFooterData = async () => {
        const apiSiteURL = window.location.hostname === 'engineering.nbcunicareers.com' ? process.env.REACT_APP_API_SITE_URL_PROD : process.env.REACT_APP_API_SITE_URL_DEV;
        try {
            const res = await axios.get(
                apiSiteURL + Config.headerFooter.api
            );
            if (typeof res.data == 'object') {
                setFooterData(res.data);
            } else {
                setFooterData(null);
            }
            return res;
        } catch (err) {
            return console.error("Fetching data failed", err);
        }
    };

    const OptanonWrapper = () => {
        if (window?.OneTrust?.getGeolocationData()?.country === "US") {
            setChoiceIcon(true);
        }
        setCookieButtonText(window?.OneTrust?.GetDomainData()?.CookieSettingButtonText);
    }

    useEffect(() => {
        getFooterData();
        // OptanonWrapper();
        const timer = setTimeout(() => {
             OptanonWrapper();
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <div className="television-program">
                <p>To view film and television programs, visit <a href="https://www.uphe.com/" target="_blank" rel="noreferrer">UPHE.com</a>, watch select titles on <a href="https://www.peacocktv.com/" target="_blank" rel="noreferrer">Peacock.</a></p>
            </div>
            {
                footerData ? (
                    <div className="careers-site-footer" role="contentinfo" aria-label="footer navigation" >
                        <div className="container-fluid">
                            <div className="site-footer-section-1">
                                {/* social links */}
                                <div className="careers-social-links" aria-labelledby="footer-social-links">
                                    <h5 className="social-follow-text">Follow NBCUniversal Careers:</h5>
                                    <ul className="footer-social-icons" role="contentinfo" aria-label="social navigation">
                                        {
                                            footerData.footer.footerSocialMenu.map((data, i) =>
                                                <li className={data.title} key={i}>
                                                    <a target="_blank" rel="noreferrer" href={data.link} title={data.title} className="footer-social-item">
                                                    </a>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                                {/* footer help links */}
                                <div className="other-footer-links">
                                    <ul className="d-flex">
                                        {
                                            footerData.footer.footerHelpMenu.map((data, i) =>
                                                <li key={i}>
                                                    <a target="_blank" rel="noreferrer" href={data.link} title={data.title} className="help-link">
                                                        <span className={data.title}></span>
                                                        <span>{data.title}</span>
                                                    </a>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="site-footer-section-2">
                                <div className="footer-flex-links">
                                    {/* footer menu links */}
                                    <div className="footer-additional-link">
                                        <ul className="footer-additional-link-list">
                                            {
                                                footerData.footer.footerMenu.map((data, i) =>
                                                    <li key={i}>
                                                        {
                                                            data.title === "Your Privacy Choices" ? (
                                                                <button id="ot-sdk-btn" className={`footer-nav-item choice-icon ot-sdk-show-settings ${choiceIcon ? 'us' : ''}`}>{cookieButtonText}</button>
                                                            ) : (
                                                                <a target="_blank" rel="noreferrer" href={data.link} title={data.title} className="footer-nav-item">{data.title}</a>
                                                            )
                                                        }
                                                    </li>
                                                )
                                            }

                                        </ul>
                                    </div>
                                    <div className="footer-additional-link">
                                        <ul className="footer-additional-link-list">
                                            {
                                                footerData.footer.footerMenu3.map((data, i) =>
                                                    <li key={i}>
                                                        <a target="_blank" rel="noreferrer" href={data.link} title={data.title} className="footer-nav-item">{data.title}</a>
                                                    </li>
                                                )
                                            }

                                        </ul>
                                    </div>
                                    {/* footer english spanish pdf links */}
                                    <div className="footer-static-link">
                                        <ul className="menu-static-link">
                                            {
                                                footerData.footer.footerMenu2.map((data, i) =>
                                                    <li key={i}>
                                                        <a target="_blank" rel="noreferrer" href={data.link} className={data.title} title={data.title}>{data.title}</a>
                                                        <div className="static-links-text">
                                                            {
                                                                data?.below?.map((innerData, j) =>
                                                                    <a key={j} target="_blank" rel="noreferrer" href={innerData.link} title={innerData.title}>{innerData.title}</a>
                                                                )
                                                            }
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                        <JoinTalentCommunity />
                                    </div>
                                    <div className="footer-nbcu-logo">
                                        {
                                            footerData.footer.footerLogo2 === 'null' ? (
                                                <img src="../../assets/images/NBCU-Logo-Tagline.svg" alt="Tagline Logo" />
                                            ) : (
                                                <img src={footerData.footer.footerLogo2} alt="Tagline Logo" />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="site-footer-section-3">
                                <div className="copyright-section">
                                    <a target="_blank" rel="noreferrer" href={footerData.header.siteUrl} title="NBCUnicareers" className="footer-logo-btm">
                                        {
                                            footerData.footer.footerLogo1 === 'null' ? (
                                                <img src="../../assets/images/nbcu-careers-logo-blue.svg" alt="NBCUnicareers" />
                                            ) : (
                                                <img src={footerData.footer.footerLogo1} alt="NBCUnicareers" />
                                            )
                                        }
                                    </a>
                                    <div className="copyright-text">
                                        &copy; {footerData.footer.copyRightText}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ScrollTopComponent />
                    </div>
                ) : (
                    null
                )
            }

        </>
    );
}

export default Footer;
