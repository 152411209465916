import React from 'react';
import JobCategoryDetails from '../components/JobCategoryDetails';

function JobCategoryDepartment(props) {
    return (
        <>
            <section className="department-section">
                <JobCategoryDetails />
            </section>
        </>
    )
}

export default JobCategoryDepartment;