import React from "react";

function ScrollTopComponent() {
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <>
            <div className="top-to-btm">
                <div className="scroll-top" onClick={goToTop}>
                    <img src="../../assets/images/back-to-top.svg" alt="Top Arrow" className="arrow-top" />
                </div>

            </div>
        </>
    )
}

export default ScrollTopComponent